module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

  siteTitle: 'FiniteLoop', // Navigation and Site Title
  siteLongTitle: 'Experts in Technical / Cloud Advisory, Architecture & Salesforce',
  siteTitleAlt: 'FiniteLoop', // Alternative Site title for SEO
  siteUrl: 'https://finiteloop.io', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/img/logo.png', // Used for SEO and manifest
  googleAnalyticsID: 'UA-115473854-1', // GA tracking ID.
  // siteBanner: '/social/banner.jpg',
  siteDescription: 'FiniteLoop - We are a boutique consulting firm focusing on experience design and highly scalable technical architecture',

  // siteFBAppID: '123456789', // Facebook App ID
  userTwitter: '_finiteloop', // Twitter Username
  // ogSiteName: 'minimal', // Facebook Site Name

  // Manifest and Progress color
  themeColor: '#A83723',
  backgroundColor: '#2b2e3c',

  // Settings for typography.js
  headerFontFamily: 'Neptune-Light',
  bodyFontFamily: 'Neptune-Light',
  baseFontSize: '18px',
}
